import { startOfMonth } from "date-fns";
import sanitizeHtml from "sanitize-html";
import { z } from "zod";

import {
  getAvailableWithdrawalAmount,
  newMortgageDownPaymentInformation,
} from "../../utils";
import { LenderEnum } from "../generic";

// *** NOTE: importing from @shared/utils is not working ***
// It create a circular dependency and return an `undefined` for the `dateIsFutureOf` function
function dateIsFutureOf(now: Date) {
  return (value: string) => {
    const [year, month, day] = value.split("-");
    const valueAsDate = new Date(+year, +month - 1, +day, 0, 0, 0, 0);

    // Set as start of the day
    const today = new Date(now.setHours(0, 0, 0, 0));

    return valueAsDate >= today;
  };
}

import { RouteMortgageType } from "./routes";

export enum TimingEnum {
  // From old website
  // we don't need to support these values anymore
  // SIGNED_OFFER = "SIGNED_OFFER",
  // FOUND_PROPERTY = "FOUND_PROPERTY",
  // ACTIVELY_LOOKING = "ACTIVELY_LOOKING",
  // NOT_LOOKING = "NOT_LOOKING",
  // NEW Feature flagged values
  MADE_OFFER = "MADE_OFFER",
  MAKING_OFFER_SOON = "MAKING_OFFER_SOON",
  GET_PREAPPROVED = "GET_PREAPPROVED",
  JUST_CURIOUS = "JUST_CURIOUS",
}

export enum PropertyTypeEnum {
  HOME_OR_CONDO = "HOME_OR_CONDO",
  SECOND_HOME = "SECOND_HOME",
  // This duplicate is intentional, and exists because in the back-end
  // 'SECOND_HOME_OR_COTTAGE' is different than 'SECONDARY_HOME_OR_COTTAGE'
  // in some parts of the application
  SECOND_HOME_OR_COTTAGE = "SECOND_HOME_OR_COTTAGE",
  SECONDARY_HOME_OR_COTTAGE = "SECONDARY_HOME_OR_COTTAGE",
  DUPLEX = "DUPLEX",
  TRIPLEX = "TRIPLEX",
  FOURPLEX = "FOURPLEX",
  RENTAL = "RENTAL",
  OTHER = "OTHER",
  COTTAGE = "COTTAGE",
  COMMERCIAL = "COMMERCIAL",
}

export enum DownPaymentRatioEnum {
  TWENTY_MORE = "TWENTY_MORE",
  TWENTY_LESS = "TWENTY_LESS",
  UNKNOWN = "UNKNOWN",
}

const castToBoolean = z.preprocess(
  (value) => value === true || value === "true",
  z.boolean()
);

/**
 * New mortgage form
 * ownerOccupied: boolean
 * propertyType: string
 * propertyValue: number
 * downpayment: number
 * timing: string
 */
export const formNewMortgage = z.object({
  quoteType: z.literal("NEW"),
  ownerOccupied: z.enum(["true", "false"], {
    errorMap: () => ({
      message: "invalid",
    }),
  }),
  propertyType: z.nativeEnum(PropertyTypeEnum, {
    errorMap: () => ({
      message: "invalid",
    }),
  }),
  propertyValue: z.coerce
    .number({
      required_error: "required",
      invalid_type_error: "invalid",
    })
    .min(1, { message: "minAmount" })
    .max(9_999_999, { message: "maxAmount" }),
  downpayment: z.coerce
    .number({
      required_error: "required",
      invalid_type_error: "invalid",
    })
    .min(1, { message: "minAmount" }), // max is the property value
  timing: z.nativeEnum(TimingEnum, {
    errorMap: () => ({
      message: "invalid",
    }),
  }),
  acceptanceDate: z.string().optional(),
});

/**
 * Renewal form
 * ownerOccupied: boolean
 * propertyValue: number
 * mortgageAmount: number (balance, remaining mortgage balance)
 * downPaymentRatio: TWENTY_LESS | TWENTY_MORE | UNKNOWN
 * lender: string
 * renewalSchedule: string
 * renewalScheduleDate: string
 */
export const formRenewalMortgage = z.object({
  quoteType: z.literal("RENEWAL"),
  ownerOccupied: z.enum(["true", "false"], {
    errorMap: () => ({
      message: "invalid",
    }),
  }),
  propertyValue: z.coerce
    .number({
      required_error: "required",
      invalid_type_error: "invalid",
    })
    .min(1, { message: "minAmount" })
    .max(9_999_999, { message: "maxAmount" }),
  mortgageAmount: z.coerce
    .number({
      required_error: "required",
      invalid_type_error: "invalid",
    })
    .min(1, { message: "minAmount" })
    .max(9_999_999, { message: "maxAmount" }),
  downPaymentRatio: z.nativeEnum(DownPaymentRatioEnum),
  lender: z.nativeEnum(LenderEnum),
  lenderOther: z.string().optional(),
  renewalSchedule: z.string(),
  renewalScheduleDate: z
    .string({
      required_error: "error:required",
      invalid_type_error: "error:required",
    })
    .trim()
    .refine(dateIsFutureOf(startOfMonth(new Date())), {
      message: "error:dateShouldBeFuture",
    }),
});

export const renewalMortgageAmountSchema = formRenewalMortgage
  .pick({
    propertyValue: true,
    mortgageAmount: true,
  })
  .refine((data) => data.mortgageAmount <= data.propertyValue, {
    path: ["mortgageAmount"],
    message: "maxAmount",
  });

/**
 * Refinance form
 * ownerOccupied: boolean
 * propertyValue: number
 * mortgageAmount: number (balance, remaining mortgage balance)
 * additionalFundAmount: number
 * lender: string
 */
export const formRefinanceMortgage = z.object({
  quoteType: z.literal("REFINANCE"),
  ownerOccupied: z.enum(["true", "false"], {
    errorMap: () => ({
      message: "invalid",
    }),
  }),
  propertyValue: z.coerce
    .number({
      required_error: "required",
      invalid_type_error: "invalid",
    })
    .min(1, { message: "minAmount" })
    .max(9_999_999, { message: "maxAmount" }),
  mortgageAmount: z.coerce
    .number({
      required_error: "required",
      invalid_type_error: "invalid",
    })
    .min(0, { message: "minAmount" })
    .max(9_999_999, { message: "maxAmount" }),
  additionalFundAmount: z.coerce
    .number({
      required_error: "required",
      invalid_type_error: "invalid",
    })
    .min(1, { message: "minAmount" })
    .max(9_999_999, { message: "maxAmount" }),
  lender: z.nativeEnum(LenderEnum),
  lenderOther: z
    .string()
    .optional()
    .transform((e) => (e === "" ? undefined : e)),
});

export const refinanceMortgageAmountSchema = formRefinanceMortgage
  .pick({
    propertyValue: true,
    mortgageAmount: true,
  })
  .refine((data) => data.mortgageAmount <= data.propertyValue, {
    path: ["mortgageAmount"],
    message: "maxAmount",
  });

export const refinanceAmountSchema = formRefinanceMortgage
  .pick({
    mortgageAmount: true,
    additionalFundAmount: true,
    ownerOccupied: true,
    propertyValue: true,
  })
  .superRefine((data, ctx) => {
    const mortgageBalance = data.mortgageAmount;
    const additionalFundAmount = data.additionalFundAmount;
    const propertyValue = data.propertyValue;

    const amountAllowed = getAvailableWithdrawalAmount({
      propertyValue: propertyValue,
      mortgageBalance: mortgageBalance,
    });

    if (additionalFundAmount > amountAllowed) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        message: "maxAmount",
        path: ["additionalFundAmount"],
        maximum: amountAllowed,
        inclusive: true,
        type: "number",
      });
    }

    return z.NEVER;
  });

export const lenderSchema = z
  .object({
    lender: z.nativeEnum(LenderEnum),
    lenderOther: z
      .string()
      .optional()
      .transform((e) => (e === "" ? undefined : e)),
  })
  .refine((data) => data.lender !== "OTHER" || data.lenderOther, {
    message: "lenderOtherRequired",
    path: ["lenderOther"],
  });

// We can't use `pick` to select the ownerOccupied field only from `gaqSchema`
// because of the discriminated union, so we need to create a new schema
// to validate the step ownerOccupied field only
export const ownerOccupiedSchema = formNewMortgage.pick({
  ownerOccupied: true,
});
export const propertyValueSchema = formNewMortgage.pick({
  propertyValue: true,
});

// Using a different schema to validate the downpayment step
// because we don't have a full data yet and of the refine/superfine
// we can't use `pick` to select the downpayment field only
export const downpaymentSchema = formNewMortgage
  .pick({
    downpayment: true,
    ownerOccupied: true,
    propertyValue: true,
  })
  .superRefine((data, ctx) => {
    const isRentalProperty = data.ownerOccupied === "false";
    const propertyValue = data.propertyValue;
    const downpayment = data.downpayment;

    if (downpayment >= propertyValue) {
      ctx.addIssue({
        code: z.ZodIssueCode.too_big,
        message: "maxAmount",
        path: ["downpayment"],
        maximum: propertyValue,
        inclusive: true,
        type: "number",
      });

      return z.NEVER;
    }

    // validate the down payment business logic
    const warning = newMortgageDownPaymentInformation(
      propertyValue,
      downpayment,
      isRentalProperty
    );

    console.log("warning", warning);
    if (warning) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "fixErrorBeforeSubmit",
        path: ["downpayment"],
      });
    }
  });

const sanitize = (schema: z.ZodString) =>
  z.preprocess((value) => sanitizeHtml(String(value)), schema);

export const gaqContactSchema = z.object({
  createdAt: z.literal("GET_A_QUOTE"),
  firstName: sanitize(
    z
      .string({ required_error: "error:required" })
      .trim()
      .min(1, { message: "error:required" })
      .max(63, { message: "error:tooLong" })
  ),
  lastName: sanitize(
    z
      .string({ required_error: "error:required" })
      .trim()
      .min(1, { message: "error:required" })
      .max(63, { message: "error:tooLong" })
  ),
  phone: sanitize(
    z
      .string({ required_error: "error:required" })
      .trim()
      .min(12, { message: "error:invalid" })
      .max(20, { message: "error:invalid" })
  ),
  email: sanitize(
    z
      .string()
      .trim()
      .email({ message: "error:invalidEmail" })
      .min(6, { message: "error:required" })
  ),
  region: z
    .string({ required_error: "error:required" })
    .trim()
    .nonempty("error:required"),
  leadDistributeConsentAgreement: z.boolean(),
});

export const signupSchema = gaqContactSchema
  .omit({
    createdAt: true,
  })
  .merge(
    z.object({
      createdAt: z.literal("LOGIN"),
      password: z
        .string({
          required_error: "error:required",
        })
        .min(12, { message: "error:minCharactersRequired" })
        .max(32, { message: "error:maxCharactersRequired" })
        .refine(
          (password) => {
            // must contain at least one uppercase letter, one lowercase letter and one number
            const hasAtLeastOneUppercaseLetter = /[A-Z]/.test(password);
            const hasAtLeastOneLowercaseLetter = /[a-z]/.test(password);
            const hasAtLeastOneNumber = /[0-9]/.test(password);

            return (
              hasAtLeastOneUppercaseLetter &&
              hasAtLeastOneLowercaseLetter &&
              hasAtLeastOneNumber
            );
          },
          {
            message: "error:weakPassword",
          }
        ),
      passwordConfirmation: z.string(),
    })
  )
  .refine(
    ({ password, passwordConfirmation }) => password === passwordConfirmation,
    {
      message: "error:passwordMismatch",
      path: ["passwordConfirmation"],
    }
  );

export type FormNewMortgage = z.infer<typeof formNewMortgage>;
export type FormRenewalMortgage = z.infer<typeof formRenewalMortgage>;
export type FormRefinanceMortgage = z.infer<typeof formRefinanceMortgage>;
export type FormGAQ = z.infer<typeof gaqSchema>;
export type FormContactGAQ = z.infer<typeof gaqContactSchema>;
export type FormSignup = z.infer<typeof signupSchema>;

///
export const gaqSchema = z.discriminatedUnion("quoteType", [
  formNewMortgage,
  formRenewalMortgage,
  formRefinanceMortgage,
]);

export const getFormSchema = (quoteType: RouteMortgageType) => {
  if (quoteType === "new-mortgage") {
    return formNewMortgage;
  }

  if (quoteType === "renewal") {
    return formRenewalMortgage;
  }

  if (quoteType === "refinance") {
    return formRefinanceMortgage;
  }

  throw new Error("Invalid quoteType");
};

export const isNewMortgage = (data: FormGAQ): data is FormNewMortgage => {
  return data.quoteType === "NEW";
};

export const isRenewalMortgage = (
  data: FormGAQ
): data is FormRenewalMortgage => {
  return data.quoteType === "RENEWAL";
};

export const isRefinanceMortgage = (
  data: FormGAQ
): data is FormRefinanceMortgage => {
  return data.quoteType === "REFINANCE";
};

// --- Casted to boolean payload
export const formNewMortgagePayload = formNewMortgage.merge(
  z.object({ ownerOccupied: castToBoolean })
);
export type FormNewMortgagePayload = z.infer<typeof formNewMortgagePayload>;

export const formRenewalMortgagePayload = formRenewalMortgage.merge(
  z.object({ ownerOccupied: castToBoolean })
);
export type FormRenewalMortgagePayload = z.infer<
  typeof formRenewalMortgagePayload
>;

export const formRefinanceMortgagePayload = formRefinanceMortgage.merge(
  z.object({ ownerOccupied: castToBoolean })
);
export type FormRefinanceMortgagePayload = z.infer<
  typeof formRefinanceMortgagePayload
>;

export const gaqSchemaToPayload = z.discriminatedUnion("quoteType", [
  formNewMortgagePayload,
  formRenewalMortgagePayload,
  formRefinanceMortgagePayload,
]);
export type FormGAQPayload = z.infer<typeof gaqSchemaToPayload>;

export const isNewMortgagePayload = (
  data?: FormGAQPayload
): data is FormNewMortgagePayload => {
  return data?.quoteType === "NEW";
};

export const isRenewalMortgagePayload = (
  data?: FormGAQPayload
): data is FormRenewalMortgagePayload => {
  return data?.quoteType === "RENEWAL";
};

export const isRefinanceMortgagePayload = (
  data?: FormGAQPayload
): data is FormRefinanceMortgagePayload => {
  return data?.quoteType === "REFINANCE";
};

// Matrix / Rates page schema
const withRegionCode = z.object({
  regionCode: z.string().default("QC"),
  postalCode: z.union([
    z.literal(""),
    z.string().min(3).max(7).default("").catch(""),
  ]),
});
const withRateFilters = z.object({
  productTerm: z.string().default("5_YEAR").catch("5_YEAR"),
  amortization: z.coerce.number().default(25).catch(25),
  productType: z.enum(["FIXED", "VARIABLE"]).default("FIXED").catch("FIXED"),
});

export const rateFiltersSchema = z
  .discriminatedUnion("quoteType", [
    formNewMortgagePayload.merge(withRegionCode).merge(withRateFilters),
    formRenewalMortgagePayload.merge(withRegionCode).merge(withRateFilters),
    formRefinanceMortgagePayload.merge(withRegionCode).merge(withRateFilters),
  ])
  .superRefine((data, ctx) => {
    // if is new, check propertyValue <= downpayment
    if (data.quoteType === "NEW") {
      const propertyValue = data.propertyValue;
      const downpayment = data.downpayment;

      if (propertyValue <= downpayment) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          message: "maxAmount",
          path: ["downpayment"],
          maximum: downpayment,
          inclusive: true,
          type: "number",
        });

        return z.NEVER;
      }
    }

    // if is renewal, check mortgageAmount <= propertyValue
    if (data.quoteType === "RENEWAL") {
      const mortgageBalance = data.mortgageAmount;
      const propertyValue = data.propertyValue;

      if (mortgageBalance > propertyValue) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          message: "maxAmount",
          path: ["mortgageAmount"],
          maximum: propertyValue,
          inclusive: true,
          type: "number",
        });

        return z.NEVER;
      }
    }
  });
export type RateFiltersSchema = z.infer<typeof rateFiltersSchema>;

export const matrixSchema = z
  .discriminatedUnion("quoteType", [
    formNewMortgagePayload.merge(withRegionCode).merge(withRateFilters),
    formRenewalMortgagePayload.merge(withRegionCode).merge(withRateFilters),
    formRefinanceMortgagePayload.merge(withRegionCode).merge(withRateFilters),
  ])
  .superRefine((data, ctx) => {
    // if is renewal, check mortgageAmount <= propertyValue
    if (data.quoteType === "RENEWAL") {
      const mortgageBalance = data.mortgageAmount;
      const propertyValue = data.propertyValue;

      if (mortgageBalance > propertyValue) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          message: "maxAmount",
          path: ["mortgageAmount"],
          maximum: propertyValue,
          inclusive: true,
          type: "number",
        });

        return z.NEVER;
      }
    }
  })
  // Transform the data to match the Quote service API
  .transform((data) => {
    // Rename fields for the API
    if (data.quoteType === "NEW") {
      const pickedData = pick(data, [
        "productTerm",
        "amortization",
        "productType",
        "regionCode",
        "postalCode",
        "ownerOccupied",
        "propertyType",
        "acceptanceDate",
        "timing",
        "quoteType",
      ]);
      return {
        ...pickedData,
        downPaymentAmount: Math.ceil(data.downpayment),
        propertyValue: Math.ceil(data.propertyValue),
        transactionType: data.quoteType,
        interestLevel: data.timing,
      };
    }

    if (data.quoteType === "RENEWAL") {
      const pickedData = pick(data, [
        "productTerm",
        "amortization",
        "productType",
        "regionCode",
        "postalCode",
        "ownerOccupied",
        "lender",
        "lenderOther",
        "quoteType",
        "renewalSchedule",
        "renewalScheduleDate",
        "downPaymentRatio",
      ]);

      return {
        ...pickedData,
        mortgageAmount: Math.ceil(data.mortgageAmount),
        propertyValue: Math.ceil(data.propertyValue),
        transactionType: data.quoteType,
      };
    }

    if (data.quoteType === "REFINANCE") {
      const pickedData = pick(data, [
        "productTerm",
        "amortization",
        "productType",
        "regionCode",
        "postalCode",
        "ownerOccupied",
        "lender",
        "lenderOther",
        "quoteType",
      ]);

      return {
        ...pickedData,
        transactionType: data.quoteType,
        propertyValue: Math.ceil(data.propertyValue),
        additionalFundAmount: Math.ceil(data.additionalFundAmount),
        mortgageAmount: Math.ceil(data.mortgageAmount),
        // NOTE: idk why this field is required on refinance
        // because it is only for renewal
        // https://github.com/nestoca/website/blob/cd12a85cddcf1f4636a0c6c6bc05b001aecf37c8/frontend/src/sagas/rates.sagas.ts#L162
        downPaymentRatio: "UNKNOWN",
      };
    }

    return data;
  });
export type MatrixSchema = z.infer<typeof matrixSchema>;

function pick<Data extends object, Keys extends keyof Data>(
  data: Data,
  keys: Keys[]
): Pick<Data, Keys> {
  const result = {} as Pick<Data, Keys>;

  for (const key of keys) {
    result[key] = data[key];
  }

  return result;
}
