import { z } from "zod";

import { getAsNumber, isEmpty } from "../../utils";

export const amountSchema = z.preprocess(
  (v) => (isEmpty(v) ? null : getAsNumber(v)),
  z
    .number({
      required_error: "form:error.required",
      invalid_type_error: "form:error.required",
      coerce: false,
    })
    .max(9_999_999, { message: "form:error.maxDollarAmount" })
    .min(1, { message: "form:error.required" })
);
