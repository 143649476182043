import {
  isNewMortgagePayload,
  isRefinanceMortgagePayload,
  isRenewalMortgagePayload,
} from "../constants/schema";

import { getCookie, getGAClientId, parseUtmzCookie } from "./cookie";
import { getUserKey } from "./ld";
import { passwordGenerator } from "./password-generator";

import type {
  FormContactGAQ,
  FormSignup,
  MortgageType,
  FormGAQPayload,
} from "../constants/schema";
import type { TenantClient } from "@nestoca/multi-tenant";

const SHORT_POSTAL_CODE_LENGTH = 3;

type GetCreateAccountPayloadBaseProps = {
  tenant: TenantClient | null;
  locale?: string;
  partner: string;
  subPartnerId: number;
  postalCode?: string;
  affiliateMarketingId: string | null;
  impressionsTrackingId: string | null;
};

type GetCreateAccountPayloadPropsWithGaqData =
  GetCreateAccountPayloadBaseProps & {
    userInfo: FormContactGAQ;
    gaqData: FormGAQPayload | undefined;
  };

type GetCreateAccountPayloadPropForSignup = GetCreateAccountPayloadBaseProps & {
  userInfo: FormSignup;
};

type GetCreateAccountPayloadProps =
  | GetCreateAccountPayloadPropsWithGaqData
  | GetCreateAccountPayloadPropForSignup;

export type GetCreateAccountPayload = ReturnType<
  typeof getCreateAccountPayload
>;
// Remove the `undefined` from the union type
export type CreateAccountPayload = Exclude<GetCreateAccountPayload, undefined>;

export const getCreateAccountPayload = (
  props: GetCreateAccountPayloadProps
) => {
  const {
    tenant,
    locale = "en",
    partner,
    subPartnerId,
    postalCode,
    affiliateMarketingId,
    impressionsTrackingId,
  } = props;

  const hasRequiredData = isPropsForSignup(props)
    ? !!props.userInfo.password
    : !!props.gaqData;

  if (!tenant?.auth0 || !hasRequiredData) {
    return;
  }

  const utmCookie = getCookie("__utmz") || getCookie("__utmzz") || "";
  const utm = parseUtmzCookie(utmCookie);
  const fbp = getCookie("_fbp");
  const fbc = getCookie("_fbc");
  const gaClientId = getGAClientId();

  const isDirect = utm.channel === "Direct";
  const isSocial = utm.channel === "Social";

  const password = isPropsForSignup(props)
    ? props.userInfo.password
    : passwordGenerator(16);

  // TODO: for Co-branding epic.
  // WealthSimple is the only partner that doesn't require email consent,
  // the consent is implied by the user signing up
  // so we can set the emailConsent to `true` if the partner is WealthSimple
  //
  // NOTE: on the old version the `leadDistributeConsentAgreement` control the email consent
  // ref: https://github.com/nestoca/website/blob/2ee7ad1353d4dd6809209e9f2215cbf2b2081edc/frontend/src/components/partners-email-consent-checkbox/partners-email-conset-checkbox.component.tsx#L24
  const emailConsent =
    partner === "wealthSimple"
      ? true
      : props.userInfo.leadDistributeConsentAgreement;

  const isShortPostalCode = postalCode?.length === SHORT_POSTAL_CODE_LENGTH;

  const anonymousAccountId = getUserKey();

  const cfFields = getCfFields(
    props.userInfo,
    isPropsWithGaqData(props) ? props.gaqData : undefined
  );

  // send a request to the nesto api to create the user
  const payload = {
    firstName: props.userInfo.firstName,
    lastName: props.userInfo.lastName,
    email: props.userInfo.email,
    phone: props.userInfo.phone,
    region: props.userInfo.region,
    language: locale,
    leadDistributeConsentAgreement:
      props.userInfo.leadDistributeConsentAgreement,
    password,
    // TODO: Note: if false the BE doesn't save the password and we can't authenticate the user
    // if set to true. Do the BE trigger the welcome / change password email
    // https://nestoca.atlassian.net/browse/OG-10024
    passwordSpecified: true,
    createdAt: props.userInfo.createdAt,
    postalCode: !isShortPostalCode ? postalCode : "",
    partialPostalCode: isShortPostalCode ? postalCode : "",
    emailConsent,
    // was used for proprio direct (deprecated partner) IN GAQ,
    // will show the consent page flow for other partners in CMA
    partnerAgreement: false,
    partnerAgreementSpecified: false,
    // Co-branding
    partner,
    subPartnerId,
    //
    // Marketing: utm cookies etc...
    //
    gaClientId,
    anonymousAccountId,
    marketingChannel: utm.channel,
    utmSource: utm.source,
    utmMedium: utm.medium,
    utmCampaign: utm.campaign,
    utmTerm: utm.term,
    utmContent: utm.content,
    affiliateMarketingId,
    impressionsTrackingId,
    impressionsTrackingIdSpecified: impressionsTrackingId ? true : false,
    fbp,
    fbc,
    gclid: utm.gclid,
    wbraid: utm.wbraid,
    msclkid: utm.msclkid,
    direct: isDirect,
    social: isSocial ? utm.source : false,
    formName: isPropsForSignup(props)
      ? "signup"
      : isPropsWithGaqData(props)
        ? getMarketingFormName(props.gaqData?.quoteType)
        : "",
    // other marketing fields sent to Salesforce
    ...cfFields,
  };

  return payload;
};

const getCfFields = (
  userInfo: FormContactGAQ | FormSignup,
  gaqData: FormGAQPayload | undefined
) => {
  if (isNewMortgagePayload(gaqData)) {
    return {
      cfMortgageType: gaqData.propertyType,
      cfPropertyValue: gaqData.propertyValue,
      cfDownPayment: gaqData.downpayment,
      cfProvince: userInfo.region,
      cfOwnerOccupied: gaqData.ownerOccupied,
      cfTransactionPeriod: gaqData.timing,
    };
  }

  if (isRenewalMortgagePayload(gaqData)) {
    return {
      cfPropertyValue: gaqData.propertyValue,
      cfBalance: gaqData.mortgageAmount,
      cfMortgageAmount: gaqData.mortgageAmount,
      cfDownPayment: gaqData.downPaymentRatio,
      cfLender: gaqData.lender,
      cfLenderOther: gaqData.lenderOther,
      cfTransactionPeriod: gaqData.renewalSchedule,
      cfTransactionPeriodDate: gaqData.renewalScheduleDate,
      cfProvince: userInfo.region,
      cfOwnerOccupied: gaqData.ownerOccupied,
    };
  }

  if (isRefinanceMortgagePayload(gaqData)) {
    return {
      cfPropertyValue: gaqData.propertyValue,
      cfBalance: gaqData.mortgageAmount,
      cfAdditionalFund: gaqData.additionalFundAmount,
      cfLender: gaqData.lender,
      cfLenderOther: gaqData.lenderOther,
      cfProvince: userInfo.region,
      cfOwnerOccupied: gaqData.ownerOccupied,
    };
  }

  return {
    cfProvince: userInfo.region,
  };
};

export const getTypeFromFormName = (mortgageType: MortgageType) =>
  ({
    NEW: "new mortgage",
    RENEWAL: "renewal",
    REFINANCE: "refinance",
  })[mortgageType];

export const getMarketingFormName = (mortgageType?: MortgageType) =>
  ({
    NEW: "NEW_MORTGAGE",
    RENEWAL: "RENEWAL_MORTGAGE",
    REFINANCE: "REFINANCE_MORTGAGE",
  })[mortgageType || "NEW"];

const isPropsForSignup = (
  data: GetCreateAccountPayloadProps
): data is GetCreateAccountPayloadPropForSignup => {
  return (
    (data as GetCreateAccountPayloadPropForSignup).userInfo.createdAt ===
    "LOGIN"
  );
};

const isPropsWithGaqData = (
  data: GetCreateAccountPayloadProps
): data is GetCreateAccountPayloadPropsWithGaqData => {
  return (
    (data as GetCreateAccountPayloadPropsWithGaqData).userInfo.createdAt ===
    "GET_A_QUOTE"
  );
};
