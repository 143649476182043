import * as z from "zod";

// *** NOTE: importing from @shared/utils is not working ***
// *** so we are importing with relative path the functions here ***
// It create a circular dependency
import { isValidYear, dateExceedsOneYear } from "../../utils/dates";
import { getAsNumber } from "../../utils/get-as-number";
import { isEmpty } from "../../utils/is-empty";
import {
  GarageSizeEnum,
  GarageTypeEnum,
  HeatingTypeEnum,
  SewageTypeEnum,
  SpaceUnitsEnum,
  TargetPropertyConstructionTypeEnum,
  TargetPropertyStyleEnum,
  TargetPropertyTenureEnum,
  TargetPropertyTypeEnum,
  WaterTypeEnum,
  addressSchema,
  amountFrequencyOptional,
  rentalIncomeAmountFrequency,
  rentalIncomeAmountFrequencyOptional,
} from "../application";
import { amountSchema } from "../generic";

export const garageSchema = z
  .object({
    present: z.boolean({
      required_error: "form:error.required",
      invalid_type_error: "form:error.required",
    }),
    sizeType: z
      .nativeEnum(GarageSizeEnum, {
        errorMap: () => {
          return { message: "form:error.required" };
        },
      })
      .optional()
      .nullable(),
    type: z
      .nativeEnum(GarageTypeEnum, {
        errorMap: () => {
          return { message: "form:error.required" };
        },
      })
      .optional()
      .nullable(),
  })
  .superRefine((values, ctx) => {
    if (values.present) {
      const resSizeType = z
        .nativeEnum(GarageSizeEnum, {
          errorMap: () => {
            return { message: "form:error.required" };
          },
        })
        .safeParse(values.sizeType);
      const resType = z
        .nativeEnum(GarageTypeEnum, {
          errorMap: () => {
            return { message: "form:error.required" };
          },
        })
        .safeParse(values.type);

      if (!resSizeType.success) {
        resSizeType.error.issues.forEach((issue) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "form:error.required",
            path: ["sizeType", ...issue.path],
          });
        });
      }

      if (!resType.success) {
        resType.error.issues.forEach((issue) => {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "form:error.required",
            path: ["type", ...issue.path],
          });
        });
      }
    }
  });

export const optionalAmountSchema = z.preprocess(
  (v) => (isEmpty(v) ? null : getAsNumber(v)),
  z
    .number()
    .max(9_999_999, {
      message: "form:error.maxDollarAmount",
    })
    .nullable()
    .optional()
);

export const coOwnersheepFeesOptional = z
  .object({
    amount: z
      .preprocess(
        (v) => (isEmpty(v) ? null : getAsNumber(v)),
        z
          .number({
            required_error: "required",
            invalid_type_error: "type",
            coerce: false,
          })
          .max(9_999_999, {
            message: "form:error.maxDollarAmount",
          })
          .nullable()
      )
      .optional(),
    frequency: z.preprocess(
      (v) => (isEmpty(v) ? "" : v),
      z
        .enum([
          "WEEKLY",
          "BIWEEKLY",
          "MONTHLY",
          "SEMIMONTHLY",
          "SEMIANNUALLY",
          "ANNUALLY",
          "",
        ])
        .optional()
    ),
    heatingIncluded: z.boolean().nullable().optional(),
    ratio: z.number().nullable().optional(),
  })
  // make sure if one is filled, the other is filled
  .refine(
    (schema) => {
      // frequency is not empty and amount is null / empty field
      if (!isEmpty(schema.frequency) && isEmpty(schema.amount)) {
        return false;
      }

      return true;
    },
    {
      message: "form:error.missingAmount",
      path: ["amount"],
    }
  )
  .refine(
    (schema) => {
      // we want to support negative values for the amount and just check it is not 0
      return schema.amount && schema.amount !== 0 ? !!schema.frequency : true;
    },
    {
      message: "form:error.missingFrequency",
      path: ["frequency"],
    }
  );

export const amountUnitSchema = z.object({
  amount: z.preprocess(
    (v) => (isEmpty(v) ? null : getAsNumber(v)),
    z
      .number({
        required_error: "required",
        invalid_type_error: "form:error.required",
        coerce: false,
      })
      .min(1, { message: "form:error.required" })
  ),
  unit: z.preprocess(
    (v) => (isEmpty(v) ? "" : v),
    z.nativeEnum(SpaceUnitsEnum, {
      errorMap: () => {
        return { message: "form:error.required" };
      },
    })
  ),
});

export const baseFoundPropertySchema = z.object({
  isFound: z.boolean(),
  address: addressSchema,
  purpose: z.enum(
    [
      "OWNER_OCCUPIED",
      "OWNER_OCCUPIED_AND_RENTAL",
      "RENTAL",
      "SECONDARY_RESIDENCE",
    ],
    {
      errorMap: () => {
        return { message: "form:error.required" };
      },
    }
  ),
  acceptanceDate: z
    .string()
    .trim()
    .min(1, { message: "form:error.required" })
    .refine(
      (data) => {
        return dateExceedsOneYear(new Date())(data);
      },
      {
        message: "target-property:acceptanceDate.error",
      }
    ),
  purchasePrice: amountSchema,
  estimatedPropertyValue: amountSchema,
  schoolAndMunicipalTaxes: z.object({
    amount: amountSchema,
    year: z.preprocess(
      (v) => (isEmpty(v) ? null : getAsNumber(v)),
      z.number({
        required_error: "form:error.required",
        invalid_type_error: "form:error.required",
      })
    ),
    paidOnYourBehalf: z.boolean().nullable().optional(),
  }),
  rentalIncome: rentalIncomeAmountFrequencyOptional.nullable().optional(),
  propertyType: z.nativeEnum(TargetPropertyTypeEnum, {
    errorMap: () => {
      return { message: "form:error.required" };
    },
  }),
  propertyStyle: z.nativeEnum(TargetPropertyStyleEnum, {
    errorMap: () => {
      return { message: "form:error.required" };
    },
  }),
  constructionType: z.nativeEnum(TargetPropertyConstructionTypeEnum, {
    errorMap: () => {
      return { message: "form:error.required" };
    },
  }),
  yearBuilt: z.preprocess(
    (v) => (isEmpty(v) ? null : getAsNumber(v)),
    z.number({ invalid_type_error: "form:error.required" }).refine(
      (data) => {
        return isValidYear(data);
      },
      {
        message: "Invalid year",
      }
    )
  ),
  tenure: z.nativeEnum(TargetPropertyTenureEnum, {
    errorMap: () => {
      return { message: "form:error.required" };
    },
  }),
  livingSpace: amountUnitSchema,
  lotSize: amountUnitSchema,
  heatingType: z.nativeEnum(HeatingTypeEnum, {
    errorMap: () => {
      return { message: "form:error.required" };
    },
  }),
  coOwnershipFees: coOwnersheepFeesOptional,
  heatingCost: amountFrequencyOptional.nullable().optional(),
  heatingCostIncluded: z.boolean().nullable(),
  waterType: z.nativeEnum(WaterTypeEnum, {
    errorMap: () => {
      return { message: "form:error.required" };
    },
  }),
  sewageType: z.nativeEnum(SewageTypeEnum, {
    errorMap: () => {
      return { message: "form:error.required" };
    },
  }),
  garage: garageSchema,
});

export const isFoundPropertySchema = baseFoundPropertySchema.merge(
  z.object({
    isFound: z.literal(true),
  })
);

export type IsFoundProperty = z.infer<typeof isFoundPropertySchema>;

export const isNotFoundProperty = z
  .object({
    isFound: z.literal(false),
    address: z.object({
      stateCode: z
        .string({ required_error: "form:error.required" })
        .trim()
        .nonempty("form:error.required"),
      countryCode: z.string().trim(),
      streetNumber: z.string().trim().nullable().optional(),
      street: z.string().trim().nullable().optional(),
      unit: z.string().trim().optional(),
      city: z.string().trim().nullable().optional(),
      postalCode: z.string().trim().nullable().optional(),
    }),
  })
  .merge(
    baseFoundPropertySchema.pick({
      purchasePrice: true,
      propertyType: true,
      purpose: true,
      acceptanceDate: true,
      rentalIncome: true,
    })
  );

export type IsNotFoundProperty = z.infer<typeof isNotFoundProperty>;

export const purposeUnion = z.discriminatedUnion("purpose", [
  z.object({
    purpose: z.enum(["OWNER_OCCUPIED", "SECONDARY_RESIDENCE"], {
      required_error: "form:error.required",
    }),
    rentalIncome: rentalIncomeAmountFrequencyOptional.nullable().optional(),
  }),
  z.object({
    purpose: z.enum(["OWNER_OCCUPIED_AND_RENTAL", "RENTAL"], {
      errorMap: () => {
        return { message: "form:error.required" };
      },
    }),
    rentalIncome: rentalIncomeAmountFrequency,
  }),
]);

export const discriminatedSchema = z
  .discriminatedUnion("isFound", [isFoundPropertySchema, isNotFoundProperty])
  .and(purposeUnion);

export type TargetPropertyForm = z.infer<typeof discriminatedSchema>;
