import { useMemo, useState, useEffect } from "react";

import { useTenantSettings } from "@nestoca/multi-tenant";
import {
  Box,
  Button,
  Flex,
  FlexProps,
  Skeleton,
  Typography,
  useBreakpointValue,
  ButtonIcon,
} from "@nestoca/ui";
import {
  useGetApplicationsByApplicantId,
  useGetApplicationsByTransactionType,
} from "@shared/api/hooks/applications";
import { useServicingAssets } from "@shared/api/hooks/mortgages-servicing";
import {
  ACTIVE_APPLICATION_STATE,
  Application,
  TransactionTypeEnum,
} from "@shared/constants";
import {
  ApplicationSelectorGrid,
  ApplicationSelectorGridSkeleton,
  useApplicationsFilters,
  HomeHeader,
} from "@shared/ui";
import { useTypedRouter } from "@shared/utils";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import {
  BsHouse,
  BsHouseFill,
  BsPencil,
  BsPlusCircleFill,
  BsPlusLg,
} from "react-icons/bs";
import { z } from "zod";

import { ApplicationChipFilters } from "../application-selector/application-chip-filters";

import styles from "./home-page.module.scss";
import { OpenApplicationsModal } from "./open-applications-modal";

const schema = z.object({
  transactionType: z.nativeEnum(TransactionTypeEnum).optional(),
});

interface HomePageProps {
  getApplicationUrl: (application: Application) => URL | string;
}
export type BreakpointValueType = {
  wrappersGap: FlexProps["gap"];
  applicationsGap?: FlexProps["gap"];
};
export type ApplicationWithIndex = Application & { index: number };
export const HomePage = ({ getApplicationUrl }: HomePageProps) => {
  const { t } = useTranslation("applications");
  const {
    enableNewApplicationCreate,
    enableLinkToPortal,
    enableGaq,
    enableDeleteApplication,
  } = useTenantSettings();
  const { query, replace } = useTypedRouter(schema);
  const { data: allApplications } = useGetApplicationsByApplicantId();
  const filters = useApplicationsFilters(allApplications || []);
  const { data: servicingAssets } = useServicingAssets();
  const { locale } = useRouter();

  // for local testing it redirects to the Servicing Portal staging
  const baseUrl = process.env.NEXT_PUBLIC_SERVICING_PORTAL_HREF
    ? process.env.NEXT_PUBLIC_SERVICING_PORTAL_HREF
    : "";
  const [deleteApplicationMode, setDeleteApplicationMode] = useState(false);

  const isMobile = useBreakpointValue({ default: true, md: false });
  const breakpointValue = useBreakpointValue({
    default: { wrappersGap: 0, applicationsGap: 4 } as BreakpointValueType,
    md: { wrappersGap: 7, applicationsGap: 6 } as BreakpointValueType,
  });

  const {
    data: applications,
    isPending,
    isError,
  } = useGetApplicationsByTransactionType(
    query.transactionType ?? filters?.[0]?.value
  );

  useEffect(() => {
    if (!applications?.length && filters[0]?.value) {
      replace({
        pathname: "/",
        query: {
          transactionType: filters[0].value,
        },
      });
    }
  }, [applications, filters, replace]);

  const splitApplications = useMemo(
    () =>
      applications?.reduce(
        (acc, currApplication, i) => {
          if (currApplication.applicationState === "FUNDED") {
            acc.fundedApplications.push({ ...currApplication, index: i });
          } else {
            acc.openApplications.push({ ...currApplication, index: i });
          }
          return acc;
        },
        {
          fundedApplications: [] as ApplicationWithIndex[],
          openApplications: [] as ApplicationWithIndex[],
        }
      ),
    [applications]
  );

  const applicationsInProgress = useMemo(() => {
    return allApplications?.filter((application: Application) =>
      ACTIVE_APPLICATION_STATE.includes(application.applicationState)
    );
  }, [allApplications]);

  const [isOpenApplicationsModalOpen, setIsOpenApplicationsModalOpen] =
    useState(false);

  const hasOpenApplications =
    splitApplications && splitApplications?.openApplications.length > 0;

  // const showCreateAppButton = enableGaq.value && hasOpenApplications;
  const showCreateAppButton =
    enableGaq.value && enableNewApplicationCreate.value;

  const servicingAssetsIncludesFundedApp =
    splitApplications?.fundedApplications.some((app) => {
      return servicingAssets?.find(
        (asset) => asset.loans[0].applicationId === app.id
      );
    });

  return (
    <Flex
      direction="column"
      gap={breakpointValue?.wrappersGap}
      align="center"
      className={styles.home}
    >
      <Flex
        className={styles["home-wrapper-header"]}
        direction="column"
        gap={breakpointValue?.wrappersGap}
      >
        <HomeHeader />
        {isMobile && showCreateAppButton && (
          <Button
            className={styles["add-button"]}
            onClick={() => setIsOpenApplicationsModalOpen(true)}
            variant="primary"
            size="medium"
            data-dd-action-name="add new"
          >
            {t("createApplicationButton")}
          </Button>
        )}
        <ApplicationChipFilters />
      </Flex>
      {/* skeleton while loadings */}
      {isPending && (
        <Flex
          gap={breakpointValue?.applicationsGap}
          className={styles.applications}
        >
          <Flex gap={3}>
            <BsHouse size={20} />
            <Box>
              <Skeleton width={126} />
            </Box>
          </Flex>
          <ApplicationSelectorGridSkeleton />
        </Flex>
      )}
      <Flex
        gap={breakpointValue?.applicationsGap}
        className={styles.applications}
      >
        <Flex gap={5} align="center">
          <Flex gap={3}>
            <BsHouse size={20} />
            <Box>{t("openApplications")}</Box>
            {enableDeleteApplication.value && hasOpenApplications && (
              <DeleteApplicationButton
                deleteApplicationMode={deleteApplicationMode}
                setDeleteApplicationMode={setDeleteApplicationMode}
              />
            )}
          </Flex>
          {!isMobile && showCreateAppButton && (
            <Button
              size="small"
              className={styles["new-app-link"]}
              rightIcon={<BsPlusCircleFill />}
              onClick={() => setIsOpenApplicationsModalOpen(true)}
            >
              {t("createApplicationButton")}
            </Button>
          )}
        </Flex>
        {hasOpenApplications ? (
          <ApplicationSelectorGrid
            getApplicationUrl={getApplicationUrl}
            applications={splitApplications?.openApplications ?? []}
            deleteApplicationMode={deleteApplicationMode}
          />
        ) : (
          <Link href="/getaquote" passHref legacyBehavior>
            <Flex
              as="a"
              align="center"
              gap={4}
              className={styles["create-new-app"]}
            >
              <BsPlusLg className={styles["create-new-app__icon"]} />
              <Typography weight={7} size={1}>
                {t("createApplicationButton")}
              </Typography>
            </Flex>
          </Link>
        )}
      </Flex>
      {splitApplications && splitApplications.fundedApplications.length > 0 && (
        <Flex
          gap={breakpointValue?.applicationsGap}
          className={styles.applications}
        >
          <Flex
            gap={3}
            className={clsx({
              [styles["applications--disabled"]]:
                deleteApplicationMode && isMobile,
            })}
          >
            <BsHouseFill size={20} color="var(--color-green-500)" />
            <Box>{t("fundedMortgages")}</Box>
            {!!servicingAssets?.length &&
              servicingAssetsIncludesFundedApp &&
              enableLinkToPortal.value && (
                <Link
                  href={
                    servicingAssets.length > 1
                      ? `${baseUrl}/portal/${locale}`
                      : `${baseUrl}/portal/${locale}/property/${servicingAssets[0].id}`
                  }
                  passHref
                  legacyBehavior
                >
                  <Button
                    as="a"
                    variant="alternative"
                    size="small"
                    className={styles.portal}
                  >
                    {t("portal")}
                  </Button>
                </Link>
              )}
          </Flex>
          <ApplicationSelectorGrid
            getApplicationUrl={getApplicationUrl}
            applications={splitApplications?.fundedApplications}
            deleteApplicationMode={deleteApplicationMode}
          />
        </Flex>
      )}
      {isError && <Box>{t("failedToLoadApplications", { ns: "common" })}</Box>}
      <OpenApplicationsModal
        isModalOpen={isOpenApplicationsModalOpen}
        closeModal={() => setIsOpenApplicationsModalOpen(false)}
        applications={applicationsInProgress ?? []}
        getApplicationUrl={getApplicationUrl}
      />
    </Flex>
  );
};

const DeleteApplicationButton = ({
  deleteApplicationMode,
  setDeleteApplicationMode,
}: {
  deleteApplicationMode: boolean;
  setDeleteApplicationMode: (value: boolean) => void;
}) => {
  const isMobile = useBreakpointValue({ default: true, md: false });
  const { t } = useTranslation("common");

  if (!isMobile) {
    return null;
  }

  return (
    <>
      {deleteApplicationMode ? (
        <Button
          size="xsmall"
          variant="secondary"
          onClick={() => setDeleteApplicationMode(!deleteApplicationMode)}
        >
          {t("done")}
        </Button>
      ) : (
        <ButtonIcon
          aria-label="edit applications"
          icon={<BsPencil size={20} />}
          size="xsmall"
          variant="secondary"
          onClick={() => setDeleteApplicationMode(!deleteApplicationMode)}
        />
      )}
    </>
  );
};
